import { FAQ } from '~/types/data-array'

const ServicesFAQ: FAQ[] = [
  {
    question: 'What is software development?',
    answer:
      'In fact, software development involves designing, creating, testing, and maintaining computer programs or applications. It combines programming, analysis, and problem-solving skills to develop software that meets specific requirements and provides the desired functionality.',
  },
  {
    question: 'How much does it cost to deliver software development services?',
    answer:
      'The cost of software development services can vary significantly depending on the project’s complexity, the development team’s size, the software’s specific requirements, and more. For example, hourly rates can range from $25 to $150 or more, depending on the development team’s expertise.',
  },
  {
    question: 'How long does it take to develop a software product?',
    answer:
      'Factors like changes in requirements, technical challenges, and the need for testing and quality assurance can influence the development timeline. Small projects with limited features might be completed in a few weeks/months, while more complex projects can take several months or years to develop.',
  },
]

export default ServicesFAQ
