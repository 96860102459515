import Title from '~/components/shared/Title'
import { BaseMock } from '~/types/mock'
import { cx } from '~/utils'

import * as containerStyles from './CompanyWhyHireBlock.module.scss'

interface CompanyWhyHireBlockProps {
  dataContent: BaseMock[]
}

const CompanyWhyHireBlock = ({ dataContent }: CompanyWhyHireBlockProps) => (
  <div className="container container-md">
    <div className="row">
      {dataContent.map(({ title, description }) => (
        <div
          key={title}
          className={cx(
            'col-12 col-md-6',
            containerStyles.companyWhyHireBlock__col,
          )}
        >
          <div className={containerStyles.companyWhyHireBlock__item}>
            <Title
              headingLevel="h3"
              className={containerStyles.companyWhyHireBlock__title}
            >
              {title}
            </Title>
            <div className={containerStyles.companyWhyHireBlock__description}>
              {description}
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)

export default CompanyWhyHireBlock
