import { lazy } from 'react'

import EmptyFallback from '~/components/render-kit/EmptyFallback'
import RenderWhenVisible from '~/components/render-kit/RenderWhenVisible'
import ClientVideoQuoteSliderMock from '~/mock/ClientVideoQuoteSlider'

const ClientVideoQuotesSlider = lazy(
  () => import('~/components/sliders/ClientVideoQuotesSlider'),
)

const ClientVideoQuotesSliderSection = () => (
  <RenderWhenVisible
    withSuspense
    fallback={<EmptyFallback width="100%" height={516} />}
  >
    <ClientVideoQuotesSlider
      activeSlideIndex={0}
      dataContent={ClientVideoQuoteSliderMock}
    />
  </RenderWhenVisible>
)

export default ClientVideoQuotesSliderSection
