import { ClientQuoteSliderMock } from '~/types/mock'

const ClientVideoQuoteSlider: ClientQuoteSliderMock[] = [
  {
    image: 'clientTradeaboat',
    name: 'Celso Prado',
    location: 'Australia',
    position: 'Manager at the Adventures Group',
    link: 'online-marketplace-for-boats',
    quote: [
      {
        text: '“They are like my secret weapon. We are in verticals where market leaders have hundreds of in-house developers and multi-billion dollars in annual revenue. And despite all of that, our lean team, with the help from Codica, is growing its market share year after year.”',
      },
    ],
    videoLink: 'https://www.youtube.com/embed/K9aOABDeKPI',
  },
  {
    image: 'clientPMK',
    name: 'David Watkins',
    location: 'USA',
    position: 'Founder of PlanMyKids',
    link: 'kids-activities-service-marketplace',
    quote: [
      {
        text: '“The PlanMyKids journey started when my family struggled to plan our own kid’s activities. As busy parents, we were time-constrained, the information we needed was all over the place, and coordinating with family felt like it required a project management certification.”',
      },
    ],
    videoLink: 'https://www.youtube.com/embed/dzxMCDLghZQ',
  },
  {
    image: 'clientRef',
    name: 'Leon Zinger',
    location: 'Israel',
    position: 'Founder at RefDental',
    link: 'dental-imaging-management-system',
    quote: [
      {
        text: '“What we like most about working with Codica is their deep understanding of our business needs. They quickly grasped our initial idea and delivered our Minimum Viable Product within the specified time and budget. Thanks to this, we could get user feedback fast”',
      },
    ],
    videoLink: 'https://www.youtube.com/embed/r1tbx4MEmrY',
  },
]

export default ClientVideoQuoteSlider
