import { ProcessMock } from '~/types/mock'

const ServicesProcess: ProcessMock[] = [
  {
    num: '1',
    title: 'Research & discovery',
    description:
      'At this stage, we form a vision of the future software product.',
    classNames: 'cardOne',
  },
  {
    num: '2',
    title: 'Product design (UX & UI)',
    description:
      'We create a user journey and make intuitive software that end-users will adore.',
    classNames: 'cardTwo',
  },
  {
    num: '3',
    title: 'Product development',
    description:
      'Our developers build the project according to the most cutting-edge practices and standards.',
    classNames: 'cardThree',
  },
  {
    num: '4',
    title: 'Optimization and QA',
    description:
      'At Codica, we always test the project we made to deliver efficient and quality software.',
    classNames: 'cardFour',
  },
  {
    num: '5',
    title: 'Deployment, monitoring, and support',
    description:
      'As a full-cycle software development firm, we support your digital solutions on a regular basis.',
    classNames: 'cardFive',
  },
]

export default ServicesProcess
