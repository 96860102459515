import { BaseMock } from '~/types/mock'

const ServicesWhyChoose: BaseMock[] = [
  {
    title: 'Reduce costs and launch fast',
    description:
      'Our development process is fully transparent and efficient, enabling us to deliver your software solution on time and within the budget.',
  },
  {
    title: 'Obtain expert recommendations',
    description:
      'Having built multiple custom web applications, our skilled team provides the best solution for your business issues.',
  },
  {
    title: 'Dedicated team that cares',
    description:
      'We are proud of our result-oriented team that prioritizes transparency and communication and focuses on high-value relationships that continue.',
  },
  {
    title: 'The right solution meeting your needs',
    description:
      'Client success is our main aim. We thoroughly align your business needs with software functionality to provide the best value.',
  },
  {
    title: 'The best practice standards',
    description:
      'We keep us with all industry trends and optimize our software development and quality assurance process according to the latest standards.',
  },
  {
    title: 'Maintenance and support after the launch',
    description:
      'Our assistance doesn’t stop when your software product goes live. Codica’s reliable support team will ensure your platform works smoothly.',
  },
]

export default ServicesWhyChoose
