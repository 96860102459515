import { Link } from 'gatsby'

import { ExpertiseSectionMock } from '~/types/mock'

const ServicesBusinesses: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIconSmall_7',
    title: 'Software development for startups',
    description: (
      <>
        Need to make a prototype, create a new MVP or scale an existing one?
        Anyway, you should act quickly. And we can help! Launch your software
        project and validate your idea fast and on budget with our proven
        <Link
          to="/blog/custom-software-development-process/"
          className="ml5"
          key="keyDevProcess"
        >
          custom software development process
        </Link>
        .
      </>
    ),
  },
  {
    icon: 'sprite3DIconSmall_8',
    title: 'Small & medium businesses',
    description:
      'Codica’s team of skilled experts will make a highly-responsive and visually attractive software project for your company, applying the latest industry standards. We will also be glad to support and further improve your existing solution.',
  },
  {
    icon: 'sprite3DIconSmall_9',
    title: 'Custom enterprise software development',
    link: '/services/custom-enterprise-software-development/',
    description:
      'Our experienced software engineers are always ready to help you with any digital transformation. We understand your issues, and will help you automate business processes in a cost-effective way with a custom product crafted specifically for your requirements.',
  },
]

export default ServicesBusinesses
