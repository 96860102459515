import { DevExpertiseCompanyMock } from '~/types/mock'

const ServicesDevExpertise: DevExpertiseCompanyMock[] = [
  {
    title: '30+',
    text: 'successfully delivered marketplaces',
  },
  {
    title: '$56+',
    text: 'generated by our clients',
  },
  {
    title: '40+',
    text: 'realized marketplace discoveries',
  },
  {
    title: '9+',
    text: 'years in marketplace development',
  },
]

export default ServicesDevExpertise
