import { Link } from 'gatsby'

import { ExpertiseSectionMock } from '~/types/mock'

const ServicesDevelopment: ExpertiseSectionMock[] = [
  {
    icon: 'sprite3DIcon_4',
    title: 'Custom software development services',
    link: '/services/custom-software-development/',
    description:
      'We create high-performing and efficient custom software for businesses that require more than a standard application.',
  },
  {
    icon: 'sprite3DIcon_1',
    title: 'Online marketplace development',
    link: '/services/online-marketplace-development/',
    description: (
      <>
        Have an idea to make
        <Link
          to="/blog/how-to-create-b2b-marketplace/"
          className="ml5"
          key="keyOMDone"
        >
          B2B
        </Link>
        ,
        <Link to="/blog/build-b2c-marketplace/" className="ml5" key="keyOMDtwo">
          B2C
        </Link>
        , or,
        <Link
          to="/blog/build-c2c-marketplace/"
          className="mx5"
          key="keyOMDthree"
        >
          C2C
        </Link>
        marketplace? We will
        <Link
          to="/blog/how-to-build-online-marketplace/"
          className="mx5"
          key="keyOMDfour"
        >
          build an online marketplace
        </Link>
        that your customers will love.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_3',
    title: 'MVP development services',
    link: '/services/mvp-development/',
    description: (
      <>
        Launching a startup? Our expert team will
        <Link
          to="/blog/how-to-create-minimum-viable-product/"
          className="mx5"
          key="keyMVP"
        >
          build an MVP
        </Link>
        to transform your idea into an effective, customer-centric product.
      </>
    ),
  },
  {
    icon: 'sprite3DIcon_9',
    title: 'Product discovery',
    link: '/services/product-discovery/',
    description:
      'The product discovery sessions help to build a strong foundation for your future solution, make informed decisions, and prevent risks from the start.',
  },
  {
    icon: 'sprite3DIcon_2',
    title: 'SaaS development services',
    link: '/services/saas-development/',
    description:
      'Moving to the cloud? With Codica, you have all chances to become an industry leader with a robust and highly scalable SaaS solution.',
  },
  {
    icon: 'sprite3DIcon_13',
    title: 'PWA development services',
    link: '/services/progressive-web-apps-development/',
    description:
      'Looking to increase your online presence? We can make your business stand out with a cross-platform progressive web app.',
  },
  {
    icon: 'sprite3DIcon_12',
    title: 'UX/UI design services',
    link: '/services/ui-ux-design/',
    description:
      'Our creative designers make attractive user-oriented web apps that include a beautiful interface and an intuitive UX.',
  },
  {
    icon: 'sprite3DIcon_5',
    title: 'Mobile app development services',
    link: '/services/mobile-app-development/',
    description:
      'We will create high-quality, feature-rich mobile apps for iOS and Android platforms tailored to your business requirements.',
  },
  {
    icon: 'sprite3DIcon_11',
    title: 'DevOps services',
    link: '/services/devops/',
    description:
      'We can optimize your software delivery pipeline using the latest tools and technologies to improve efficiency and reduce time-to-market.',
  },
  {
    icon: 'sprite3DIcon_19',
    title: 'QA testing services',
    link: '/services/quality-assurance/',
    description:
      'Codica’s experts provide comprehensive testing services to ensure your software meets all quality standards and functions flawlessly.',
  },
]

export default ServicesDevelopment
