import { Link } from 'gatsby'

import { HomeSpecification } from '~/types/mock'

const ServicesDomainExpertise: HomeSpecification[] = [
  {
    icon: 'sprite3DIconSmall_10',
    title: 'Online marketplaces',
    description: (
      <>
        With our
        <Link
          to="/blog/custom-software-development-for-small-business/"
          className="ml5"
          key="keyExpertiseOne"
        >
          software development services
        </Link>
        , you will receive a quality, highly-performing, and user-friendly
        online marketplace that attracts users.
      </>
    ),
    link: '/case-studies/kids-activities-service-marketplace/',
  },
  {
    icon: 'sprite3DIconSmall_13',
    title: 'eCommerce',
    description:
      'At Codica, we have vast expertise and strong skills to build efficient and modern eCommerce software. So, your solution will stand out from the crowd and bring you sales.',
    link: '/case-studies/custom-multi-vendor-marketplace-platform/',
  },
  {
    icon: 'sprite3DIconSmall_14',
    title: 'Travel',
    description:
      'We are experts in creating top-notch software for the travel industry, booking portals, and travel search engines. With us, you will get a solution that meets all your needs.',
    link: '/case-studies/travel-management-platform/',
  },
  {
    icon: 'sprite3DIconSmall_15',
    title: 'Automotive',
    description:
      'Our team is here to assist you with our software development services. We will develop software that covers the needs of automotive professionals and generate profit for you.',
    link: '/services/automotive-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_4',
    title: 'Insurance',
    description:
      'Our skilled professionals will create a profitable software solution for you that provides insurance agents and their customers with all they need in one software.',
    link: '/services/insurance-software-development/',
  },
  {
    icon: 'sprite3DIconSmall_11',
    title: 'Recruiting',
    description:
      'We can help you to develop extremely powerful recruiting software that facilitates the hiring and onboarding processes of talents. We will take into account all our client’s requirements.',
    link: '/case-studies/recruitment-progressive-web-application/',
  },
  {
    icon: 'sprite3DIconSmall_16',
    title: 'Rental',
    description:
      'Our competent specialists will create a modern rental software solution for you with everything you need to start and grow your online rental business.',
    link: '/case-studies/accommodation-search-website-mvp/',
  },
  {
    icon: 'sprite3DIconSmall_17',
    title: 'Media',
    description:
      'By ordering our software development services, you will get reliable and efficient media software that wins the target audience’s attention.',
    link: '/case-studies/news-aggregator/',
  },
  {
    icon: 'sprite3DIconSmall_18',
    title: 'Healthcare',
    description:
      'At Codica, we apply our best software development practices to provide you with a cutting-edge healthcare solution that will be useful and lovable for users.',
    link: '/case-studies/dental-imaging-management-system/',
  },
]

export default ServicesDomainExpertise
