import { Link } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'

import processLine from '~/assets/images/approach/approach-frame.svg?file'
import CompanyWhyHireBlock from '~/components/blocks/CompanyWhyHireBlock'
import DevelopmentProcess from '~/components/blocks/DevelopmentProcess'
import FaqSection from '~/components/blocks/FAQ'
import HeadSection from '~/components/blocks/HeadSection'
import CallToAction from '~/components/call-to-actions/CallToAction'
import Service3DIconCard from '~/components/cards/Service3DIconCard'
import ServicesIndustriesCard from '~/components/cards/ServicesIndustriesCard'
import MainLayout from '~/components/layouts/MainLayout'
import ClientVideoQuotesSliderSection from '~/components/sections/ClientVideoQuotesSliderSection'
import PrimeContactFormSection from '~/components/sections/PrimeContactFormSection'
import PrimeSectionServices from '~/components/sections/PrimeSectionServices'
import LazyHydrate from '~/components/shared/LazyHydrate'
import SubtractBlock from '~/components/shared/SubtractBlock'
import StickyNavigation from '~/components/sticky/StickyNavigation'
import Anchors from '~/mock/services/Anchors'
import ServicesBusinesses from '~/mock/services/ServicesBusinesses'
import ServicesDevelopment from '~/mock/services/ServicesDevelopment'
import ServicesDevExpertise from '~/mock/services/ServicesDevExpertise'
import ServicesDomainExpertise from '~/mock/services/ServicesDomainExpertise'
import ServicesFAQ from '~/mock/services/ServicesFAQ'
import ServicesProcess from '~/mock/services/ServicesProcess'
import ServicesWhyChoose from '~/mock/services/ServicesWhyChoose'
import { cx, fileToImageLikeData } from '~/utils'
import ServicesDevExpertiseCompany from '~/views/Services/components/ServicesDevExpertiseCompany'
import ServicesFullVideoSection from '~/views/Services/components/ServicesFullVideoSection'
import ServicesTechStackSection from '~/views/Services/components/ServicesTechStackSection'

import * as containerStyles from './Services.module.scss'
import useServicesStaticQuery from './useServicesStaticQuery'

const Services = () => {
  const query = useServicesStaticQuery()
  const fullVideoImage = getImage(fileToImageLikeData(query.fullVideoImage))

  return (
    <MainLayout scrollTop>
      <PrimeSectionServices
        titleTop="Software"
        title="Development Services"
        description="We develop outstanding software products for any scale of business to fit all your needs."
        breadcrumbCurrent="Software Development Services"
        breadcrumbCurrentPath="/services/"
      />

      <StickyNavigation anchors={Anchors} />

      <section
        id="services"
        className={containerStyles.servicesCardBorderSection}
      >
        <HeadSection
          position="center"
          title="Software development services we provide"
          description="Based on our comprehensive experience, Codica’s skilled team creates reliable software products according to all modern web standards. We care that the solutions are designed to suit your business needs perfectly."
          titleLarge
          descriptionLarge
        />
        <Service3DIconCard dataContent={ServicesDevelopment} />
      </section>

      <section
        id="business-type"
        className={containerStyles.servicesBusinessSection}
      >
        <HeadSection
          position="center"
          title="Trusted by businesses of any size"
          description={
            <>
              With our broad experience in
              <Link
                to="/blog/custom-software-development-for-startups/"
                className="mx5"
                key="KeyBussinessTypeDescription"
              >
                custom software development for startups
              </Link>
              and established companies, we know what issues you need to address
              and how to solve them with our services.
            </>
          }
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ServicesBusinesses} />
        <SubtractBlock />
      </section>

      <CallToAction
        title="Looking for cutting-edge software solutions?"
        subtitle="Our experts help you achieve your goals."
        classNameForGA="servicesCTA_1"
        linkName="Contact us"
        link="/contacts/"
      />

      <section
        id="industries"
        className={containerStyles.servicesIndustriesSection}
      >
        <HeadSection
          position="center"
          title="Domain expertise you can rely on"
          description="Our experience developing software for specific fields has enabled us to grow our in-house expertise, which we are open to sharing with our clients. We offer consultations on every phase of software development and make reliable and modern products."
          descriptionLarge
        />
        <ServicesIndustriesCard dataContent={ServicesDomainExpertise} />
      </section>

      <section
        id="process"
        className={cx(
          containerStyles.servicesProcessSection,
          'servicesProcessSection',
        )}
      >
        <HeadSection
          position="center"
          title="Our proven web development process"
          description="At Codica, we have all skills and tools to execute an optimized and seamless software development process."
          titleLarge
          descriptionLarge
        />
        <DevelopmentProcess
          dataImage={processLine}
          dataContent={ServicesProcess}
        />
      </section>

      <section className={containerStyles.servicesDevExpertiseSection}>
        <HeadSection
          position="center"
          title="Codica’s development experience by numbers"
        />
        <ServicesDevExpertiseCompany dataContent={ServicesDevExpertise} />
      </section>

      <section
        id="tech-stack"
        className={containerStyles.servicesTechStackSection}
      >
        <HeadSection
          position="center"
          title="Perfect tech stack"
          description="Over the years, we have selected a list of tools that enable us to create high-quality custom software projects that solve all business tasks."
          descriptionLarge
        />
        <ServicesTechStackSection />
      </section>

      <CallToAction
        title="Ready to turn your software ideas into reality?"
        subtitle="Let's create something extraordinary together!"
        classNameForGA="servicesCTA_2"
        linkName="Contact us"
        link="/contacts/"
      />

      <section className={containerStyles.servicesCompanyInfoSection}>
        <HeadSection
          position="center"
          title="Why you should choose Codica for your software development services:"
          titleLarge
        />
        <CompanyWhyHireBlock dataContent={ServicesWhyChoose} />
      </section>

      <section className={containerStyles.servicesCasesSection} id="cases">
        <HeadSection
          position="center"
          title="Our case studies"
          description="We are always glad to share our successfully completed case study projects that can inspire you and give a vision of our UX/UI design services."
          descriptionLarge
        />
        <LazyHydrate whenVisible>
          <ServicesFullVideoSection
            videoLink="https://www.youtube.com/embed/A8Bd1aiwpRU"
            videoImage={fullVideoImage}
            videoTitle="Our case studies"
          />
        </LazyHydrate>
      </section>

      <section className={containerStyles.servicesQuotesSection}>
        <HeadSection
          position="center"
          title="What our clients say about Codica"
          description="We enjoy the projects that we deliver for our clients. In turn, Codica’s clients leave us pleasant reviews that inspire our expert team to work further and even better."
          descriptionLarge
        />
        <ClientVideoQuotesSliderSection />
      </section>

      <section id="free-quote">
        <PrimeContactFormSection title="Let’s build a great custom product together!" />
      </section>

      <section id="faq">
        <FaqSection title="FAQ" dataContent={ServicesFAQ} />
      </section>
    </MainLayout>
  )
}

export default Services
